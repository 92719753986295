/* eslint-disable no-unused-vars */ /* eslint-disable no-unused-vars */
<template>
  <el-dialog :before-close="close"
             :visible="addDialogVisible"
             width="1200px"
             @open="openDialog()">
    <div slot="title"
         class="left_top">
      <div class="left_icon">
        <span></span>
        <span>新建合同</span>
      </div>
      <div class="right_icon">
        <span></span>
      </div>
    </div>
    <el-form label-position="top"
             width="600px"
             :model="ruleForm"
             :rules="rules"
             ref="ruleForm"
             class="ruleForm"
             label-width="80px">
      <el-form-item label="合同名称"
                    prop="name">
        <el-input v-model="ruleForm.name"
                  placeholder="请输入合同名称"></el-input>
      </el-form-item>
      <el-form-item label="企业名称"
                    prop="workEnterpriseCode">
        <el-select v-model="ruleForm.workEnterpriseCode"
                   remote
                   reserve-keyword
                   :remote-method="query"
                   filterable
                   clearable
                   :placeholder="'请选择企业名称'"
                   style="width: 100%"
                   @change="getCity">
          <el-option v-for="item in eList"
                     :key="item.code"
                     :label="item.name "
                     :value="item.code"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="企业区域"
                    prop="workEnterpriseAreaCode">
        <el-select v-model="ruleForm.workEnterpriseAreaCode"
                   filterable
                   :placeholder="'请选择企业区域'"
                   style="width: 100%"
                   clearable
                   @change="querycity"
                   :disabled="proviceShow">
          <el-option-group v-for="group in areaSelectList"
                           :key="group.provinceName"
                           :label="group.provinceName">
            <span style="color:red"
                  slot="label">{{ group.provinceName }}</span>
            <el-option v-for="item in group.citys"
                       :key="item.code"
                       :label="item.cityName"
                       :value="item.code"></el-option>
          </el-option-group>
        </el-select>
      </el-form-item>
      <el-form-item label="企业门店"
                    prop="workEnterpriseStoreCode">
        <el-select v-model="ruleForm.workEnterpriseStoreCode"
                   filterable
                   :placeholder="'请选择企业门店'"
                   style="width: 100%"
                   :disabled="areaShow">
          <el-option v-for="item in storeList"
                     :key="item.code"
                     :label="item.name "
                     :value="item.code"></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="签署截止日期" prop="signDeadline">
        <el-date-picker type="date" placeholder="选择日期" :picker-options="pickerOptions" :editable="false"
          :clearable="false" v-model="ruleForm.signDeadline" style="width: 100%" value-format="yyyy-MM-dd HH:mm:ss">
        </el-date-picker>
      </el-form-item>-->

      <el-form-item prop="templateCode">
        <span slot="label">合同模板名称</span>
        <!-- <span style="color:red;font-size: 12px;">(未指定带盖章的模板不可选择)</span> -->
        <el-select v-model="ruleForm.templateCode"
                   placeholder="请选择合同模板"
                   style="width: 100%"
                   clearable
                   @change="templateChange"
                   v-selectLoadMore="selectLoadMore">
          <el-option v-for="(item,index) in templateOptions"
                     :disabled="item.isShow"
                     :label="item.name"
                     :key="index"
                     :value="item.code">
            <div style="display:flex;align-items:center;justify-content:space-between">
              <span style="overflow: hidden;text-overflow: ellipsis;display: -webkit-box;">{{ item.name }}</span>
              <span style="float: right">
                {{ item.isShow ? '未指定盖章位置' : ''
                }}
              </span>
            </div>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="合同分类"
                    prop="type">
        <el-select v-model="ruleForm.type"
                   disabled
                   placeholder="请选择合同分类"
                   style="width: 100%"
                   clearable>
          <el-option label="入职合同"
                     value="1"></el-option>
          <el-option label="离职合同"
                     value="2"></el-option>
          <el-option label="用工合同"
                     value="3"></el-option>
        </el-select>
      </el-form-item>
      <transition name="slide-fade">
        <div v-if="templeteObject"
             style="flex-grow: 1">
          <Divider :title="'模板参数配置'">
            <!-- <span slot="right">
              <el-button type="text" v-if="!previewDisable" style="color: grey" size="mini">预览</el-button>
              <el-button type="text" @click="prviewTemplate" v-if="previewDisable" size="mini">预览</el-button>
            </span>-->
          </Divider>
          <el-form-item v-for="(item, i) in templeteObject.fields"
                        :key="i"
                        style="width: 100%">
            <div slot="label">
              <div class="label_class">
                <span>
                  {{ item.label === '甲方名称'
                  ? '甲方（当前企业）签署人'
                  : item.label === '乙方名称'
                  ? '乙方（员工）签署人'
                  : item.label}}
                </span>
                <el-checkbox v-model="choice"
                             v-if="item.label==='乙方名称'"
                             value-key="item.value"
                             @change="choiceChange(item.value)">多选</el-checkbox>
              </div>
            </div>
            <el-input v-model="item.value"
                      :placeholder="item.label"
                      v-if="item.type === 'text'"
                      @input="inputChange"></el-input>
            <!-- <el-select
              v-model="item.value"
              multiple
              :placeholder="item.label"
              v-if="item.type === 'text'&&choice"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                @change="inputChange"
              ></el-option>
            </el-select>-->
            <div v-if="item.type === 'subject'">
              <!-- <div v-if="choice">
                <el-select
                  v-model="item.value"
                  multiple
                  :placeholder="item.label"
                  v-if="item.type === 'text'&&choice"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    @change="inputChange"
                  ></el-option>
                </el-select>
              </div>
              <div v-else>-->
              <SelectSubject v-model="item.value"
                             :choice.sync="choice"
                             :obj="templeteObject.fields"></SelectSubject>
            </div>

            <el-input type="number"
                      v-model="item.value"
                      :placeholder="item.label"
                      v-if="item.type === 'number'"
                      @input="inputChange"></el-input>

            <el-input v-model="item.value"
                      :placeholder="item.label"
                      v-if="item.type === 'defined'"
                      @input="inputChange"></el-input>

            <el-date-picker style="width: 100%"
                            v-if="item.type === 'time'"
                            v-model="item.value"
                            type="date"
                            :placeholder="item.label"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            @input="inputChange"></el-date-picker>
          </el-form-item>
        </div>
      </transition>
    </el-form>
    <div slot="footer"
         class="dialog-footer"
         style="text-align: center">
      <el-button type="primary"
                 :loading="btnShow"
                 @click="submitForm('ruleForm')">确 定</el-button>
      <el-button @click="closeDialog('ruleForm')">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
import * as _ from 'lodash';
import { api } from '/src/api/base';
import { ContractTemplate } from './contractParse';
//新增接口

export const EnterpriseAdminInfo = api('')(
  'enterprise.admin.info.json'
);
export const ContractAdd = api()('contract.add.json');
//查询详情接口
export const ContractDetail = api()('contract.describe.json');
//编辑接口
export const ContractEdit = api()('contract.edit.json');
//删除接口
export const ContractDel = api()('contract.del.json');
// 企业列表
export const workEnterpriseList = api()('work_enterprise.list.json');
// 获取企业区域列表
export const workEnterpriseAreaList = api()('work_enterprise_area.list.json');
// 合同模板列表
export const contractTemplateList = api()('contractTemplate.list.json');
// 合同模板详情
export const contractTemplateDescribe = api()('contractTemplate.describe.json');
// 获取企业区域门店
export const workEnterpriseStoreList = api()('work_enterprise_store.list.json');
// 企业名称获取企业区域列表
export const workEnterpriseAreaContainArea = api()('work_enterprise_area.containArea.json');
//删除接口
export const ContractTemplateView = api()('contractTemplate.view.json');
// export const EnterpriseList = api()("enterprise.list.json");
// export const UserList = api()("user.list.json");
export const UserSimpleList = api()('user.simple.list.json');
export const EnterpriseSimpleList = api()('enterprise.simple.list.json');
export const contractTemplateEnterpriseInfo = api()('contractTemplate.enterprise.info.json');
export const contractTemplateEnterpriseList = api('')(
  'contractTemplate.enterprise.list.json'
);
export default {
  components: {
    Divider: () => import('/src/components/divider.vue'),
    SelectSubject: () => import('./selectSubject.vue')
    //  Upload: () => import("/src/components/upload.vue")
  },
  data () {
    return {
      pickerOptions: {                                 //禁用当前日期之前的日期
        disabledDate (time) {
          //Date.now()是javascript中的内置函数，它返回自1970年1月1日00:00:00 UTC以来经过的毫秒数。
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      enterpriseInfo: {},
      areaShow: true,
      choice: false,
      proviceShow: true,
      ruleForm: {
        workEnterpriseAreaCode: '',
        name: '',
        workEnterpriseCode: '',
        workEnterpriseStoreCode: '',
        // signDeadline: '',

        type: '',

        templateCode: ''
      },
      rules: {

        name: [{ required: true, message: '请输入合同名称', trigger: 'blur' }],

        workEnterpriseCode: [[{ required: true, message: '请选择企业名称', trigger: 'blur' }]],
        workEnterpriseAreaCode: [{ required: true, message: '请选择企业区域', trigger: 'blur' }],

        type: [{ required: true, message: '请选择合同分类', trigger: 'change' }],
        templateCode: [{ required: true, message: '请选择合同模板', trigger: 'change' }]
        // workEnterpriseStoreCode: [
        //   { required: true, message: '请选择企业门店', trigger: 'blur' }
        // ],
      },
      templateOptions: [],
      areaSelectList: [],
      storeList: [],
      eList: [],
      templateList: [],
      tempTemplate: '',
      previewDisable: false,
      // options: [],
      value: '',
      templatCode: '',
      userList: [],
      focusSubject: null,
      templeteObject: null,
      btnShow: false,
      isShow: true,
      totalPage: '',
      pageNumber: 1,
      pageSize: 10,
    };
  },
  props: {
    addDialogVisible: Boolean
  },
  created () {



  },
  methods: {

    async selectLoadMore () {

      this.pageNumber = this.pageNumber + 1;
      if (this.pageNumber > this.totalPage) return;
      await this.getTemplate(); // 请求接口
    },
    enterpriseList (val) {
      const params = {
        keywords: val,
        pageNumber: 1,
        pageSize: 1000
      };
      workEnterpriseList(params).then(res => {
        this.eList = res.list

      });
    },
    query (val) {

      this.pageNumber = 1;
      this.enterpriseList(val);
    },
    async getEnterpriseAdminInfo () {
      const enterprises = await EnterpriseAdminInfo({
        code: localStorage.getItem('enterprise')
      });
      this.enterpriseInfo = enterprises
    },
    choiceChange (val) {
      console.log(val);
      val.psnId = ''
      val.psnName = ''
      val.staffCode = ''
    },
    querycity (val) {
      if (val) {
        workEnterpriseStoreList({
          workEnterpriseAreaCode: val,
          pageNumber: 1,
          pageSize: 1000
        }).then(res => {
          this.storeList = res.list
          this.ruleForm.workEnterpriseStoreCode = ''
          this.ruleForm.templateCode = ''
          this.templeteObject = false
          this.areaShow = false
          this.getTemplate()
        });

      }
      else {
        this.templateOptions = []
        this.storeList = []
        this.ruleForm.workEnterpriseStoreCode = ''
        this.ruleForm.templateCode = ''
        this.templeteObject = false
      }

    },
    getTemplate () {
      contractTemplateEnterpriseList({
        workEnterpriseCode: this.ruleForm.workEnterpriseCode,
        workEnterpriseArea: this.ruleForm.workEnterpriseAreaCode,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      }).then(res => {
        this.totalPage = Math.ceil(res.total / 10);
        this.total = res.total;
        this.templateOptions = this.templateOptions.concat(res.list).map(it => {
          if (it.config === '[]') {
            it.isShow = true
          }
          else {
            it.isShow = false
          }
          return it
        });

        // this.templateOptions = res.list.map((it) => {
        //   if (it.config === '[]') {
        //     it.isShow = true
        //   }
        //   else {
        //     it.isShow = false
        //   }
        //   return it
        // });

      });
    },

    getCity (val) {
      if (val) {
        const params = {
          workEnterpriseCode: val
        };
        workEnterpriseAreaContainArea(params).then(res => {
          this.areaSelectList = res
          this.proviceShow = false
          this.ruleForm.workEnterpriseAreaCode = ''
          this.workEnterpriseStoreList = []
          this.ruleForm.workEnterpriseStoreCode = ''
          this.ruleForm.templateCode = ''
          this.templateOptions = []
          this.areaShow = true
          this.templeteObject = false

        });
      }
      else {
        this.areaSelectList = []
        this.ruleForm.workEnterpriseAreaCode = ''
        this.workEnterpriseStoreList = []
        this.ruleForm.workEnterpriseStoreCode = ''
        this.ruleForm.templateCode = ''
        this.proviceShow = true
        this.areaShow = true
        this.templeteObject = false
      }


    },
    async getSubjectOptions (keywords) {

      if (this.focusSubject.subjectType === '1') {
        const enterprises = await EnterpriseSimpleList({
          pageNumber: 1,
          pageSize: 1000,
          keywords,
          authenticateState: '3'
        });
        this.focusSubject.subjectOptions = enterprises.list.map(it => {
          return {
            label: it.name,
            value: it.code
          };
        });
      }
      if (this.focusSubject.subjectType === '2') {
        const users = await UserSimpleList({
          pageNumber: 1,
          pageSize: 1000,
          keywords
        });
        this.focusSubject.subjectOptions = users.list.map(it => {
          return {
            label: it.name,
            value: it.code
          };
        });
      }
    },
    async setFocusSubject (item) {
      this.focusSubject = item;

    },


    async templateChange (val) {
      const result = await contractTemplateEnterpriseInfo({ code: val });
      this.templeteObject = new ContractTemplate(result);

      this.templateShow = true

      this.ruleForm.type = result.type
    },
    openDialog () {

      this.enterpriseList()

      this.getEnterpriseAdminInfo()
      this.btnShow = false
      this.choice = false
    },
    submitForm (formName) {
      var domains = this.templeteObject.fields.every(it => {
        if (it.type === 'subject') {
          if (it.value.psnId != '') {
            return true
          }
          else {
            return false
          }
        }
        else {
          if (it.value != '') {
            return true
          }
          else {
            return false
          }
        }
      })
      if (!domains) {
        this.$message({
          message: '请输入合同模板信息',
          type: 'danger'
        })
      }
      else {
        if (!this.choice) {

          this.isShow = new Set(this.templeteObject.getReplaceConfigTwo().map(m => m.psnId)).size === this.templeteObject.fields.filter(it => it.type === 'subject').length
        }
        else {
          const a = _.flatten(this.templeteObject.getReplaceConfigTwo())
          this.isShow = this.templeteObject.fields.filter(it => it.label === '乙方名称')[0].value.psnId.length + 1 === new Set(a.map(m => m.psnId)).size
        }
        if (this.isShow) {
          console.log(this.templeteObject.getReplaceConfigTwo(), 23444444444444444, this.templeteObject.getReplaceConfig()
          );
          this.$refs[formName].validate(async valid => {
            if (valid) {
              this.btnShow = true
              let act = ''
              if (this.choice) {
                if (this.templeteObject.getReplaceConfigTwo()[0].length > 1) {
                  act = JSON.stringify(this.templeteObject.getReplaceConfigTwo())
                }
                else {
                  act = '[' + JSON.stringify(this.templeteObject.getReplaceConfigTwo()) + ']'
                }
              }
              else {
                act = '[' + JSON.stringify(this.templeteObject.getReplaceConfigTwo()) + ']'
              }
              ContractAdd({
                name: this.ruleForm.name,
                workEnterpriseCode: this.ruleForm.workEnterpriseCode,
                workEnterpriseAreaCode: this.ruleForm.workEnterpriseAreaCode,
                workEnterpriseStoreCode: this.ruleForm.workEnterpriseStoreCode,
                templateCode: this.ruleForm.templateCode,
                content: JSON.stringify(this.templeteObject.getReplaceContract()),
                config: act,
                type: this.ruleForm.type
              }).then(() => {
                this.$message.success('新建合同成功!');
                this.closeDialog(formName);
                this.$router.push({
                  path: '/system/contract/list'
                });
              });
            } else {
              return false;
            }
            this.btnShow = false
          });
        }
        else {
          this.$message.info('甲方签署人和乙方签署人不能为同一人');
        }
      }


    },
    close () {


      this.closeDialog('ruleForm');
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
    },
    closeDialog (formName) {
      this.templeteObject = null
      this.proviceShow = true
      this.areaShow = true
      this.resetForm(formName)
      this.$emit('update:addDialogVisible', false);
      this.$emit('func', this.addDialogVisible);
    },
    async getList (keywords) {
      const users = await UserSimpleList({
        pageNumber: 1,
        pageSize: 1000,
        keywords
      });
      const userList = users.list
        .filter(it => it.name && it.isAuthenticated)
        .map(it => {
          return {
            type: '1',
            ...it
          };
        });
      const enterprises = await EnterpriseSimpleList({
        pageNumber: 1,
        pageSize: 1000,
        keywords
      });
      const enterpriseList = enterprises.list
        .filter(it => it.name && it.authenticateState === '3')
        .map(it => {
          return {
            type: '2',
            ...it
          };
        });
      this.userList = [...userList, ...enterpriseList];
    },
    // 获取合同模板参数列表
    contractTemplateField (content) {
      const str = (
        content.match(
          /<input\s+type="button"\s+value="\[[A-Za-z0-9_\-\u4e00-\u9fa5]+\]"\s+data-type="[a-zA-Z0-9]+"\s+data-label="[A-Za-z0-9_\-\u4e00-\u9fa5]+"\s*\/>/g
        ) || []
      ).join('');
      const reg = /[a-z\-A-Z]+="[A-Za-z0-9_\-\u4e00-\u9fa5]+"/g;
      let result;
      const list = [];
      while ((result = reg.exec(str))) {
        if (result) {
          result = result.toString();
          if (/type="button"/.test(result)) {
            list.push({});
          } else {
            result = result
              .toString()
              .replace(/data-|"/g, '')
              .split('=');
            list[list.length - 1][result[0]] = result[1];
          }
        }
      }
      return _.uniqWith(list, _.isEqual);
    },
    // 替换合同模板参数
    contractTemplateValue () {
      let tmp = this.tempTemplate;

      this.templateList.forEach(it => {
        const reg = new RegExp(`\\[${it.label}\\]`, 'g');
        tmp = tmp.replace(reg, it.value.split('@')[0]);
      });
      return tmp;
    },
    // 替换合同签署位置参数
    contractFieldValue (config) {
      let fields = config;
      this.templateList.forEach(it => {
        const reg = new RegExp(`"subjectAccount":"\\[${it.label}\\]`, 'g');
        const reg1 = new RegExp(`"subjectName":"\\[${it.label}\\]`, 'g');
        fields = fields
          .replace(reg, `"subjectAccount":"${it.value.split('@')[1]}`)
          .replace(reg1, `"subjectName":"${it.value.split('@')[0]}`);
      });
      return fields;
    },
    // 模板预览
    async prviewTemplate () {
      const temporaryTemplate = this.contractTemplateValue();
      const res = await ContractTemplateView({
        content: temporaryTemplate
      });
      window.open(res);
    },
    inputChange () {

      this.previewDisable = this.templateList.every(it => it.value);
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ruleForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.el-form-item {
  text-align: left;
  margin: 0;
  margin-bottom: 10px;
  width: 49%;
}

.left_top {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left_icon {
    display: flex;
    align-items: center;

    span:nth-child(1) {
      padding: 8px 4px;
      background: #4977fc;
      margin-right: 10px;
    }

    span:nth-child(2) {
      font-size: 16px;
      font-weight: 700;
    }
  }
}
.label_class {
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.el-form-item__label {
  padding-bottom: 0 !important;
}
</style>
